import * as React from 'react'
import { Card, Progress, Empty, Button } from 'antd'
import { Link } from 'react-router-dom'
import { hasLogin } from '../../utils'
import { myLearnList } from './../../service/index'
import { IMAGE_SERVER } from '../../service/config'
import Login from '../pages/Login'

export interface IStudyCenterProps {}
export interface IStudyCenterStates {
  login: boolean
  courseList: any[]
  trainList: any[]
  planList: any[]
}

export default class StudyCenter extends React.Component<IStudyCenterProps, IStudyCenterStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      login: hasLogin(),
      courseList: [],
      trainList: [],
      planList: [],
    }
  }
  componentDidMount() {
    this.getMyLearnList()
  }

  async getMyLearnList() {
    const params = {}
    const res = await myLearnList(params)
    if (res.success && res.data && res.data.length > 0) {
      const { planJson, itemJson, courseJson } = res.data[0]
      this.setState({
        planList: planJson,
        courseList: courseJson,
        trainList: itemJson,
      })
    }
  }

  public render() {
    return (
      <div className="app_layout_main">
        {!this.state.login && (
          <div className="mt-m">
            <Card style={{ width: '100%', height: 700 }}>
              <div className="flex-center w-h-100">
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  imageStyle={{}}
                  description={<span>您还未登录哦！</span>}
                >
                  <Login buttonTitle="去登录" />
                </Empty>
              </div>
            </Card>
          </div>
        )}
        {this.state.login && (
          <div>
            {/* {
              this.state.planList.length > 0 && 
              <Card
                title="我的培训计划"
                className="mt-m"
                bodyStyle={{ padding: 0 }}
                headStyle={{ padding: 0 }}
                bordered={false}
              >
                {this.state.planList.length === 0 && (
                  <div className="flex-h-center w-h-100">
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      imageStyle={{}}
                      description={<span>还没有计划哦</span>}
                    ></Empty>
                  </div>
                )}
                <div className="flex-wrapper">
                  {this.state.planList &&
                    this.state.planList.map((item: any) => {
                      const percent = Math.ceil(
                        (item.planLearnCourDuraToTal / item.planCourDuraTotal) * 100
                      )
                      return (
                        <Link to={'/app/study/plan?id=' + item.orderTypeId} color="gray">
                          <div className="course-box mt-m mr-m">
                            <img className="img" title={item.orderTitle} src={IMAGE_SERVER + item.coverDirectURL} alt="" />
                            <div className="title" title={item.orderTitle}>{item.orderTitle}</div>
                            <div className="flex mt-s">
                              学习进度：
                              <div style={{ width: 120 }}>
                                <Progress percent={percent} size="small" />
                              </div>
                            </div>
                          </div>
                        </Link>
                      )
                    })}
                </div>
              </Card>
            } */}
            {this.state.trainList.length > 0 && (
              <Card
                title="我的培训项目"
                className="mt-m"
                bodyStyle={{ padding: 0 }}
                headStyle={{ padding: 0 }}
                bordered={false}
              >
                {this.state.trainList.length === 0 && (
                  <div className="flex-h-center w-h-100">
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      imageStyle={{}}
                      description={<span>还没有项目哦</span>}
                    ></Empty>
                  </div>
                )}
                <div className="flex-wrapper">
                  {this.state.trainList &&
                    this.state.trainList.map((item: any) => {
                      const percent = Math.ceil(
                        (item.learnCourDuraToTal / item.courDuraTotal) * 100
                      )
                      return (
                        <Link to={'/app/study/train?id=' + item.orderTypeId} color="gray">
                          <div className="course-box mt-m mr-m">
                            <img
                              className="img"
                              src={IMAGE_SERVER + item.coverDirectURL}
                              title={item.orderTitle}
                              alt=""
                            />
                            <div className="title" title={item.orderTitle}>
                              {item.orderTitle}
                            </div>
                            <div className="flex mt-s">
                              学习进度：
                              <div style={{ width: 120 }}>
                                <Progress percent={percent} size="small" />
                              </div>
                            </div>
                          </div>
                        </Link>
                      )
                    })}
                </div>
              </Card>
            )}

            {/* <Card
              title="我的培训课程"
              className="mt-m"
              headStyle={{ padding: 0 }}
              bodyStyle={{ padding: 0 }}
              bordered={false}
            >
              {this.state.courseList.length === 0 && (
                <div className="flex-h-center w-h-100">
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    imageStyle={{}}
                    description={<span>还没有课程哦</span>}
                  ></Empty>
                </div>
              )}
              <div className="flex-wrapper">
                {this.state.courseList &&
                  this.state.courseList.map((item: any) => {
                    const percent = Math.ceil((item.learnCourDura / item.courDura) * 100)
                    return (
                      <Link to={'/app/study/course?id=' + item.orderTypeId} color="gray">
                        <div className="course-box mt-m mr-m">
                          <img
                            className="img"
                            src={IMAGE_SERVER + item.coverDirectURL}
                            title={item.orderTitle}
                            alt=""
                          />
                          <div className="title" title={item.orderTitle}>
                            {item.orderTitle}
                          </div>
                          <div className="flex mt-s">
                            学习进度：
                            <div style={{ width: 120 }}>
                              <Progress percent={percent} size="small" />
                            </div>
                          </div>
                        </div>
                      </Link>
                    )
                  })}
              </div>
            </Card> */}
          </div>
        )}
      </div>
    )
  }
}

/**
 * Created by hao.cheng on 2017/4/22.
 */
import React from 'react'
import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'

type BreadcrumbCustomProps = {
  first?: any
  second?: any
  third?: any
}
class BreadcrumbCustom extends React.Component<BreadcrumbCustomProps> {
  render() {
    const first = <Breadcrumb.Item>{this.props.first}</Breadcrumb.Item> || ''
    const second = <Breadcrumb.Item>{this.props.second}</Breadcrumb.Item> || ''
    const third = <Breadcrumb.Item>{this.props.third}</Breadcrumb.Item> || ''
    return (
      <span>
        <Breadcrumb style={{ margin: '12px 0' }}>
          <Breadcrumb.Item>
            <Link to={'/app/train'}>培训中心</Link>
          </Breadcrumb.Item>
          {first}
          {second}
          {third}
        </Breadcrumb>
      </span>
    )
  }
}

export default BreadcrumbCustom

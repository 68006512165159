import * as React from 'react'
import Breadcrumb from '../Breadcrumb'
import { Card, Tag, Button, Divider, Tabs, message, Col } from 'antd'
import {
  courseOne,
  favorItem,
  favorItemDel,
  createOrderAuto,
  orderStuList,
} from './../../service/index'
import WZFilePreview from '../videoDoc/WZFilePreview'
import CourseChapterList from './CourseChapterList'
import { Link } from 'react-router-dom'
import umbrella from 'umbrella-storage'
import { hasLogin } from '../../utils'
import { IMAGE_SERVER } from '../../service/config'
import { chapterTextProgressUpdate, chapterTextProgressFinished } from '../../service/index'
import Login from '../pages/Login'
import moment from 'moment'
import noImage from '../../style/imgs/no_image.png'

const { TabPane } = Tabs

export interface ICourseDetailProps {}
interface ICourseDetailStates {
  courseId: any
  course: any
  currentAttach: any
  orderTypeId: any
  orderType: any
  chapTextStuJson: any[]
  loginStu: any
  stuOrderList: any[]
}

export default class CourseDetail extends React.Component<ICourseDetailProps, ICourseDetailStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      courseId: props.query.id,
      orderTypeId: props.query.typeId || '',
      orderType: props.query.type || 1,
      course: {},
      currentAttach: {}, // 当前播放附件
      chapTextStuJson: [],
      loginStu: null,
      stuOrderList: [],
    }
  }
  componentDidMount() {
    let stu = null
    let user = umbrella.getLocalStorage('user')
    if (user && user.studentJson) {
      stu = user.studentJson[0]
    }
    this.setState({
      loginStu: stu,
    })
    this.getData()
    this.getOrderStuList()
  }

  componentWillUnmount() {
    this.updateProgress()
  }

  async getData() {
    const params = {
      courseId: this.state.courseId,
      orderTypeId: this.state.orderTypeId,
      orderType: this.state.orderType,
    }
    const res = await courseOne(params)

    if (res.success) {
      const course = res.data[0]

      this.setState({ course: course })

      // 初始化第一个章节
      const firstChapter =
        course.chapterJson && course.chapterJson.length > 0 ? course.chapterJson[0] : {}
      var chapterText = { textDirectURL: '', textPdfFaceURL: '', origFileName: '' }
      if (firstChapter) {
        chapterText =
          firstChapter.chapTextJson && firstChapter.chapTextJson.length > 0
            ? firstChapter.chapTextJson[0]
            : {}
        this.setState({
          currentAttach: chapterText,
        })
      }
    }
  }

  async favorItem(itemId: any, favor: boolean) {
    let res = null
    const params = { favoriteTypeId: itemId, favoriteType: 1 }
    if (favor) {
      res = await favorItem(params)
    } else {
      res = await favorItemDel(params)
    }

    if (res.success) {
      message.success('收藏成功')
      this.getData()
    } else {
      message.error('收藏失败')
    }
  }
  async getOrderStuList() {
    let stu = null
    let user = umbrella.getLocalStorage('user')
    if (user && user.studentJson) {
      stu = user.studentJson[0]
    }
    const params = {
      studentId: stu.studentId,
      orderTypeId: this.state.courseId,
      orderType: this.state.orderType,
    }
    const res = await orderStuList(params)

    if (res.success) {
      this.setState({
        stuOrderList: res.data,
      })
    }
  }
  async startStudy() {
    let res = null

    const { orderType, orderTypeId, courseId } = this.state
    const params = {
      orderType: orderType,
      orderTitle: this.state.course.title,
      orderTypeId: orderTypeId || courseId,
    }
    res = await createOrderAuto(params)
    if (res.success) {
      this.getData()
    } else {
      message.error(res.msg)
    }
  }

  async updateProgress() {
    if (
      this.state.chapTextStuJson.length == 0 ||
      !this.state.loginStu ||
      !this.state.course.isEnroll
    ) {
      return
    }
    const params = {
      courseId: this.state.courseId,
      courStuId: this.state.course.courStuId,
      chapterId: this.state.currentAttach.chapterId,
      chapTextStuJson: JSON.stringify(this.state.chapTextStuJson),
    }
    const res = await chapterTextProgressUpdate(params)
    if (res.success) {
      this.setState({
        chapTextStuJson: [],
      })
    }
  }

  async updateProgressFinished() {
    if (
      this.state.chapTextStuJson.length == 0 ||
      !this.state.loginStu ||
      !this.state.course.isEnroll
    ) {
      return
    }
    const params = {
      courseId: this.state.courseId,
      courStuId: this.state.course.courStuId,
      chapterId: this.state.currentAttach.chapterId,
      chapTextStuJson: JSON.stringify(this.state.chapTextStuJson),
    }
    const res = await chapterTextProgressFinished(params)
    if (res.success) {
      this.setState({
        chapTextStuJson: [],
      })
    }
  }

  public render() {
    const { course, currentAttach, orderType, orderTypeId, loginStu } = this.state
    let enrollLink = ''
    if (orderType == 1) {
      enrollLink = '/app/train/orderConfirm?type=course&id=' + this.state.courseId
    } else if (orderType == 2) {
      enrollLink = '/app/train/orderConfirm?type=train&id=' + orderTypeId
    } else if (orderType == 3) {
      enrollLink = '/app/train/orderConfirm?type=plan&id=' + orderTypeId
    }

    const startDate = new Date(course.enrollStartDate)
    const endDate = new Date(course.enrollEndDate)
    const now = new Date()
    const isLate = moment(moment(course.enrollEndDate).format('YYYY-MM-DD')).isBefore(
      moment(moment().format('YYYY-MM-DD'))
    )
    const isOverDate = !course.isEnroll && isLate
    const isStarted = !course.isEnroll && now.getTime() < startDate.getTime()
    const chapterList = (
      <CourseChapterList
        chapterList={course.chapterJson}
        modal={false}
        isEnroll={hasLogin() && course.isEnroll}
        playListener={(attach: any) =>
          setTimeout(() => {
            this.setState({
              currentAttach: null,
            })
            this.setState({
              currentAttach: attach,
            })
          }, 0)
        }
      />
    )
    const itemList = course.itemCourJson
    return (
      <div>
        <div className="app_layout_main">
          <Breadcrumb />
        </div>
        <div className="course-detail-header">
          <div className="cd-header-content">
            <div className="course-video">
              {currentAttach && currentAttach.textDirectURL && (
                <WZFilePreview
                  url={currentAttach.textDirectURL}
                  pdfUrl={currentAttach.textPdfFaceURL}
                  fileName={currentAttach.origFileName}
                  modal={false}
                  disable={!course.isEnroll}
                  enrollLink={enrollLink}
                  isOverDate={isOverDate}
                  onPDFPageChanged={(currentNum: any) => {
                    console.log(currentNum)
                    const chapterText = {
                      chapTextId: currentAttach.chapTextId,
                      textId: currentAttach.textId,
                      pageNum: currentNum,
                      stayDura: '',
                      playSecond: '',
                    }
                    const { chapTextStuJson } = this.state
                    chapTextStuJson.push(chapterText)
                    this.setState({
                      chapTextStuJson,
                    })
                    this.updateProgress()
                  }}
                  onVideoTimeUpdate={(time: any) => {
                    console.log(time)
                    const chapterText = {
                      chapTextId: currentAttach.chapTextId,
                      textId: currentAttach.textId,
                      pageNum: '',
                      stayDura: '',
                      playSecond: time,
                    }
                    const { chapTextStuJson } = this.state
                    chapTextStuJson.push(chapterText)
                    this.setState({
                      chapTextStuJson,
                    })
                    this.updateProgress()
                  }}
                ></WZFilePreview>
              )}
              {(!currentAttach || !currentAttach.textDirectURL) && (
                <div className="flex-center text-white">暂无资源</div>
              )}
            </div>
            <div className="course-chapters">
              <Card
                bodyStyle={{
                  background: '#2D2D2D',
                  // border: '1px solid #2D2D2D',
                  // borderRadius: '4px',
                }}
              >
                <div className="cc-card-body">
                  {/* <div className="text">报名时间</div>
                  <div className="text">
                    {course.enrollStartDate} / {course.enrollEndDate}
                  </div>
                  <Divider style={{ background: '#3C3C3C' }} /> */}
                  <div className="text">
                    售价:<span className="price">￥{course.price}</span>
                  </div>
                  <div className="flex-vcenter mt-m ">
                    {/* <div className="mr-m">
                      {!hasLogin() && <Login buttonTitle="去报名" />}
                      {hasLogin() &&
                        course.courseId &&
                        !isOverDate &&
                        !isStarted &&
                        course.isEnroll != 1 &&
                        loginStu && (
                          <Link
                            to={enrollLink}
                            color="gray"
                          >
                            <Button type="primary" block>
                              报名学习
                            </Button>
                          </Link>
                        )}

                      {hasLogin() && isOverDate && (
                        <Button type="primary" disabled block>
                          此课程已过期
                        </Button>
                      )}
                      {hasLogin() && isStarted && (
                        <Button type="primary" disabled block>
                          未开始
                        </Button>
                      )}
                    </div> */}
                    {hasLogin() && course.courseId && (
                      <Button
                        type="primary"
                        ghost
                        icon="star"
                        onClick={() => {
                          this.favorItem(course.courseId, course.isFavorite == 0)
                        }}
                      >
                        {course.isFavorite === '1' ? '取消收藏' : '收藏'}
                      </Button>
                    )}
                  </div>
                  {itemList && itemList.length > 0 && (
                    <div>
                      <Divider style={{ margin: '15px 0' }} />
                      <div>培训项目：</div>
                      {itemList.map((item: any) => (
                        <Link to={'/app/train/train?id=' + item.itemId}>
                          <div className="chapter-cell" style={{ cursor: 'pointer' }}>
                            <div className="chapter-title mt-s">{item.title}</div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              </Card>
              <Card
                className="mt-m"
                title="章节目录"
                bodyStyle={{
                  background: '#2D2D2D',
                }}
              >
                <div className="course-chapters">{chapterList}</div>
              </Card>
            </div>
          </div>
        </div>
        <div className="app_layout_main">
          <Tabs
            tabBarStyle={{
              borderBottomColor: 'transparent',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <TabPane tab="课程介绍" key="cour_detail">
              <div className="course_detail_body">
                <div className="flex">
                  <div className="course-box">
                    <img
                      className="img"
                      src={course.coverDirectURL ? IMAGE_SERVER + course.coverDirectURL : noImage}
                      alt=""
                    />
                  </div>
                  <div className="course-box ml-m">
                    <div className="title">
                      {course.title}{' '}
                      <Tag color="orange" className="ml-s">
                        课程
                      </Tag>
                      {course.categoryName && <Tag color="blue">{course.categoryName} </Tag>}
                    </div>
                    <div className="detail mt-m">
                      <span className="text-gray">
                        <span className="text-yellow mr-s">
                          {course.chapterJson && course.chapterJson.length}
                        </span>
                        章节
                      </span>
                      <span className="mt-s ml-m text-gray">
                        <span className="text-yellow mr-s">{course.popularValue}</span>人气值
                      </span>
                    </div>
                  </div>
                </div>
                <span
                  className="course-desc"
                  dangerouslySetInnerHTML={{ __html: course.abstract }}
                ></span>
              </div>
            </TabPane>
            <TabPane tab="章节目录" key="cour_directory">
              {chapterList}
            </TabPane>
          </Tabs>
        </div>
      </div>
    )
  }
}

import * as React from 'react'
import Breadcrumb from '../Breadcrumb'
import {
  Descriptions,
  Row,
  Col,
  Table,
  Modal,
  Card,
  Tag,
  Button,
  Upload,
  message,
  List,
} from 'antd'
import ApplyStuEdit from './ApplyStuEdit'
import { BASE_URL, IMAGE_SERVER } from '../../service/config'
import {
  orderOne,
  orderStuAdd,
  orderStuDel,
  orderCancel,
  courseSettingList,
} from './../../service/index'
import { RouteComponentProps } from 'react-router-dom'
import OrderStuAdd from './OrderStuAdd'
import { Link } from 'react-router-dom'
const { Column } = Table
const { confirm } = Modal

export interface IOrderDetailProps extends RouteComponentProps<any> {}
interface IOrderDetailStates {
  orderId: any
  courseId: any
  order: any
  loading: boolean
  applyStuList: any[]
  fileList: any[]
  previewVisible: boolean
  previewImage: any
  source: any
  flag: boolean
  isFile: any
  isCcieNo: any
  imgsVisible: any
  currentFiles: any
}

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export default class OrderDetail extends React.Component<IOrderDetailProps, IOrderDetailStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      orderId: props.query.orderId,
      courseId: props.query.courseId,
      source: props.query.source,
      order: {
        applyStuList: [],
        orderStuJson: [],
      },
      loading: false,
      applyStuList: [],
      fileList: [],
      previewVisible: false,
      previewImage: '',
      flag: false,
      isFile: false,
      isCcieNo: false,
      imgsVisible: false,
      currentFiles: '',
    }
    this.getOrder = this.getOrder.bind(this)
  }
  componentDidMount() {
    this.getOrder()
  }
  setParam(setArr: any) {
    const settings = setArr.map((setStr: any, index: any) => {
      let set = index == setArr.length - 1 ? setStr : setStr + '}'
      set = set
        .replace(/{/g, '{"')
        .replace(/:/g, '":"')
        .replace(/,/g, '","')
        .replace(/}/g, '"}')
        .replace(/}"/g, '}')
        .replace(/:"{/g, ':{')

      return JSON.parse(set)
    })
    if (settings) {
      settings.forEach((setting: any) => {
        if (setting.title == 'isPaper') {
          this.setState({
            isCcieNo: setting.option0 == 0 ? false : true,
          })
        } else if (setting.title == 'isFile') {
          this.setState({
            isFile: setting.option0 == 0 ? false : true,
          })
        }
      })
    }
  }

  getOrder = async () => {
    if (this.state.orderId) {
      this.setState({ loading: true })
      const res = await orderOne(this.state.orderId)
      if (res.msgStatsNum === 10) {
        message.warn('您还未登录！')
        return
      }
      if (res.success) {
        const order = res.data[0]
        let setJsonStr = res.data[0].orderSetJson
        if (setJsonStr) {
          setJsonStr = setJsonStr.replace(/=/g, ':')
          let setArr = setJsonStr.split('},')
          this.setParam(setArr)
        }
        if (order) {
          setTimeout(() => {
            this.setState({ order: order, applyStuList: order.orderStuJson })
          }, 0)
          /* const orderPayJson = order.orderPayJson
          if (orderPayJson && orderPayJson.offlineJson) {
            const offlineJson = orderPayJson.offlineJson
            const fileList = {
              uid: offlineJson.payOffId,
              name: offlineJson.origFileName,
              status: 'done',
              url: IMAGE_SERVER + offlineJson.textDirectURL,
            }
            this.setState({ order: order, applyStuList: order.orderStuJson, fileList: [fileList] })
          } else {
            this.setState({ order: order, applyStuList: order.orderStuJson })
          }*/
        }
      }
      this.setState({
        loading: false,
      })
    }
  }

  /**
   * 取消订单
   * @param orderId
   */
  async cancelOrder() {
    const params = {
      orderId: this.state.orderId,
    }
    const res = await orderCancel(params)
    if (res.success) {
      message.success('取消成功')
      setTimeout(() => {
        this.getOrder()
      }, 0)
    } else {
      message.success('取消失败')
    }
  }

  handleCancel = () => this.setState({ previewVisible: false })

  handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    })
  }

  handleChange = (e: any) => this.setState({ fileList: e.fileList })

  public render() {
    let orderStatusStr = ''
    let tagColor = ''
    const { order } = this.state

    if (order) {
      if (order.orderStatus === 1) {
        orderStatusStr = '待审核'
        tagColor = 'orange'
      } else if (order.orderStatus === 2) {
        orderStatusStr = '审核通过'
        tagColor = 'green'
      } else if (order.orderStatus === 3) {
        orderStatusStr = '审核不通过'
        tagColor = 'red'
      } else if (order.orderStatus === 4) {
        orderStatusStr = '已取消'
        tagColor = 'gray'
      } else {
        orderStatusStr = ''
      }
    }

    let status = <span></span>
    let url = ''
    if (order.orderType == 1) {
      url = '/app/train/course?id='
    } else if (order.orderType == 2) {
      url = '/app/train/train?id='
    } else if (order.orderType == 3) {
      url = '/app/train/plan?id='
    }
    let opera =
      order.orderStatus == 2
        ? this.state.order.orderStuJson.length > 0 && (
            <Link to={url + order.orderTypeId} color="gray">
              <Button className="ml-m" type="primary">
                开始学习
              </Button>
            </Link>
          )
        : this.state.order.orderStuJson.length > 0 && (
            <Link to={url + order.orderTypeId} color="gray">
              <Button className="ml-m" type="primary" disabled block>
                开始学习
              </Button>
            </Link>
          )

    /* switch (order.orderStatus) { disabled block
      case 1:
        status = <span className="text-yellow">审核中</span>
        switch (order.payStatus) {
          case 1:
            status = <span className="text-yellow">未支付</span>
            opera = (
              <div>
                <Link to={'/app/train/orderPay?orderId=' + order.orderId} color="gray">
                  <Button type="primary">去支付</Button>
                </Link>
                <Button
                  type="danger"
                  className="ml-s"
                  ghost
                  onClick={() => {
                    const self = this
                    confirm({
                      title: '确定要取消订单吗?',
                      onOk() {
                        self.cancelOrder()
                      },
                      onCancel() {
                        console.log('Cancel')
                      },
                    })
                  }}
                >
                  取消订单
                </Button>
              </div>
            )
            break
          case 2:
            status = <span className="text-yellow">审核中</span>
            break
          case 3:
            status = <span className="text-red">已取消</span>
            break
        }
        break
      case 2:
        status = <div className="text-green">审核通过</div>
        let url = ''
        if (order.orderType == 1) {
          url = '/app/train/course?id='
        } else if (order.orderType == 2) {
          url = '/app/train/train?id='
        } else if (order.orderType == 3) {
          url = '/app/train/plan?id='
        }
        opera = (
          <Link to={url + order.orderTypeId} color="gray">
            <Button className="ml-m" type="primary">
              开始学习
            </Button>
          </Link>
        )
        break
      case 3:
        status = <span className="text-red">审核不通过</span>
        break
      case 4:
        status = <span className="text-gray">已取消</span>
        break
      default:
        break
    }*/

    return (
      <div>
        <div className="app_layout_main">
          <Breadcrumb />
          <Card>
            <Row>
              <Col span={11}>
                <Descriptions size="small" title="订单信息" column={2} bordered>
                  <Descriptions.Item label="订单号" span={2}>
                    {this.state.order.orderNum}
                  </Descriptions.Item>
                  <Descriptions.Item label="培训项目" span={2}>
                    {this.state.order.orderTitle}
                  </Descriptions.Item>
                  <Descriptions.Item label="报名人数">
                    {this.state.order.orderStuNum}人
                  </Descriptions.Item>
                  <Descriptions.Item label="报名时间">{this.state.order.regtime}</Descriptions.Item>
                  <Descriptions.Item label="联络人">
                    {this.state.order.linkPerson}
                  </Descriptions.Item>
                  <Descriptions.Item label="手机号码">
                    {this.state.order.linkPhone}
                  </Descriptions.Item>
                  <Descriptions.Item label="单价">
                    {this.state.order.unitPrice || 0}元
                  </Descriptions.Item>
                  <Descriptions.Item label="总金额">
                    {this.state.order.totalAmount || 0}元
                  </Descriptions.Item>
                  {/* <Descriptions.Item label="待支付金额">
                  {this.state.order.price || 0}元
                </Descriptions.Item> */}
                  {/*  <Descriptions.Item label="状态"></Descriptions.Item>*/}
                </Descriptions>
              </Col>
              <Col span={11} offset={1}>
                <div className="ant-descriptions-title">
                  支付方式：<span style={{ fontWeight: 'normal' }}>线下支付</span>
                </div>
                <div
                  style={{
                    height: '190px',
                    width: '530px',
                    border: '1px solid #e8e8e8',
                    padding: '30px 40px',
                    wordWrap: 'break-word',
                    paddingTop: '5px',
                    paddingLeft: '15px',
                  }}
                >
                  <p>请按以下方式转账：</p>
                  <p style={{ fontWeight: 'bold', fontSize: '20px' }}>收款名称：浙江省混凝土协会</p>
                  <p style={{ fontWeight: 'bold', fontSize: '20px' }}>
                    开户行：中国建设银行宝石支行
                  </p>
                  <p style={{ fontWeight: 'bold', fontSize: '20px' }}>
                    银行账号：33001616135050018872
                  </p>
                </div>
              </Col>
            </Row>
            <div>
              <div className="ant-descriptions-title mt-s flex-wrapper">
                学员信息
                <div style={{ fontWeight: 'normal', marginLeft: '20px' }}>
                  学员数:{this.state.applyStuList.length || 0} / {this.state.order.enrollCount || 0}
                </div>
              </div>
              <Table
                className="mt-m"
                rowKey="itemId"
                size="small"
                loading={this.state.loading}
                dataSource={this.state.applyStuList}
              >
                <Column
                  title="序号"
                  dataIndex="name"
                  key="name"
                  render={(name: any, row: any, index) => index + 1}
                />
                <Column
                  title="姓名"
                  dataIndex="stuName"
                  key="stuName"
                  render={(stuName: any) => stuName}
                />
                <Column
                  title="身份证号码"
                  dataIndex="cardID"
                  key="cardID"
                  render={(cardID: any) => cardID}
                />
                <Column
                  title="单位名称"
                  dataIndex="compName"
                  key="compName"
                  render={(compName: any) => compName}
                />
                <Column title="电话" dataIndex="phone" key="phone" render={(phone: any) => phone} />
                <Column
                  title="组织机构代码"
                  dataIndex="compCode"
                  key="compCode"
                  render={(compCode: any) => compCode}
                />
                <Column
                  title="附件"
                  dataIndex="files"
                  key="files"
                  render={(files: any) => (
                    /*    <ul>
                          {files &&
                            files.map((item, index) => (
                              <li key={item.id}>
                                <span>{item.origFileName}</span>
                                <a
                                  href={BASE_URL + item.attachDirectURL}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  下载
                                </a>
                              </li>
                            ))}
                        </ul>*/
                    <div>
                      <Button
                        size="small"
                        icon="import"
                        type="primary"
                        onClick={() => {
                          this.setState({
                            imgsVisible: true,
                            currentFiles: files,
                          })
                        }}
                      >
                        {'查看'}
                      </Button>
                    </div>
                  )}
                />
                {/*this.state.order.orderStatus === 2 &&*/}
                {
                  <Column
                    title="操作"
                    dataIndex="itemId"
                    key="itemId"
                    render={(itemId: any, row: any, index: number) => (
                      <div className="flex">
                        <ApplyStuEdit
                          type="edit"
                          item={row}
                          isFile={this.state.isFile}
                          isCcieNo={this.state.isCcieNo}
                          courseId={this.state.courseId}
                          onCourseChange={async (stu: any) => {
                            const params = {
                              orderId: this.state.orderId,
                              orderStuJson: JSON.stringify([stu]),
                            }
                            const res = await orderStuAdd(params)
                            if (res.success) {
                              this.getOrder()
                            } else {
                              message.error(res.msg)
                            }
                            // const applyStuList: any[] = this.state.applyStuList
                            // const oldStu = applyStuList[index]
                            // applyStuList[index] = { ...oldStu, ...stu }
                            // this.setState({
                            //   applyStuList: applyStuList,
                            // })
                          }}
                        />
                        <Button
                          type="link"
                          color="red"
                          onClick={async () => {
                            if (row.orderStuId) {
                              const res = await orderStuDel(row.orderStuId)
                              if (res.success) {
                                this.getOrder()
                              } else {
                                message.error(res.msg)
                              }
                            } else {
                              const { applyStuList } = this.state
                              applyStuList.splice(index, 1)
                              this.setState({
                                applyStuList: applyStuList,
                              })
                            }
                          }}
                        >
                          删除
                        </Button>
                      </div>
                    )}
                  />
                }
              </Table>
              <Modal
                title="附件"
                width={'60%'}
                visible={this.state.imgsVisible}
                footer={null}
                onCancel={() => {
                  this.setState({
                    imgsVisible: false,
                  })
                }}
              >
                <div className="panel-body">
                  {this.state.currentFiles &&
                    this.state.currentFiles.length > 0 &&
                    this.state.currentFiles.split(',').map((url: any, index: any) => {
                      const startIndex = url.indexOf('/upload/')
                      const title = url.substring(startIndex + url.length)
                      return (
                        <div
                          className="course-box mr-m"
                          title={title}
                          key={index}
                          onClick={() => {
                            this.setState({
                              previewImage: url,
                              previewVisible: true,
                            })
                          }}
                        >
                          <img className="img" src={url} alt="" />
                          <div className="title">{title}</div>
                        </div>
                      )
                    })}
                </div>
              </Modal>
              <Modal
                visible={this.state.previewVisible}
                footer={null}
                onCancel={() => this.setState({ previewVisible: false })}
              >
                <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
              </Modal>
            </div>

            <div className="flex-vcenter mt-m">
              {status}
              {/* {this.state.order.orderStatus === 2 && (*/}
              {/*     <div className="ml-m">*/}
              <ApplyStuEdit
                type="add"
                isFile={this.state.isFile}
                isCcieNo={this.state.isCcieNo}
                onCourseChange={async (stu: any) => {
                  const applyStuList: any[] = this.state.applyStuList
                  /**
                   * todo 这个添加目的何在？？？
                   */
                  // applyStuList.push(stu)
                  if (
                    this.state.order.enrollCount > 0 &&
                    this.state.order.enrollCount <= applyStuList.length
                  ) {
                    message.warn('已超出报名人数限制')
                    return
                  }
                  /* this.setState({
                        applyStuList: applyStuList,
                      })*/
                  const params = {
                    orderId: this.state.orderId,
                    orderStuJson: JSON.stringify(applyStuList),
                  }
                  const res = await orderStuAdd(params)
                  if (res.success) {
                    this.getOrder()
                  } else {
                    message.error(res.msg)
                  }
                }}
              />
              {/*      </div>*/}

              {/* )}*/}
              <span className="ml-m">{opera}</span>
            </div>
          </Card>
        </div>
      </div>
    )
  }
}

import * as React from 'react'
import Breadcrumb from '../Breadcrumb'
import { Card, Tag, Button, Steps, Tabs, message } from 'antd'
import {
  trainOne,
  favorItem,
  favorItemDel,
  createOrderAuto,
  orderStuList,
} from './../../service/index'
import { IMAGE_SERVER } from '../../service/config'
import { Link } from 'react-router-dom'
import CourseCell from '../course/CourseCell'
import { hasLogin } from '../../utils'
import umbrella from 'umbrella-storage'
import Login from '../pages/Login'
import moment from 'moment'
import noImage from '../../style/imgs/no_image.png'

const { TabPane } = Tabs
const { Step } = Steps

export interface ITrainDetailProps {}
interface ITrainDetailStates {
  trainId: any
  train: any
  loginStu: any
  stuOrderList: any[]
}

export default class TrainDetail extends React.Component<ITrainDetailProps, ITrainDetailStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      trainId: props.query.id,
      train: {},
      loginStu: null,
      stuOrderList: [],
    }
  }
  componentDidMount() {
    let stu = null
    let user = umbrella.getLocalStorage('user')
    if (user && user.studentJson) {
      stu = user.studentJson[0]
    }
    this.setState({
      loginStu: stu,
    })
    this.getData()
  }

  async getData() {
    const params = { itemId: this.state.trainId }
    const res = await trainOne(params)

    if (res.success) {
      const train = res.data[0]

      this.setState({ train: train })
    }
  }

  async favorItem(itemId: any, favor: boolean) {
    let res = null
    let del = ''
    const params = { favoriteTypeId: itemId, favoriteType: 2 }
    if (favor) {
      res = await favorItem(params)
    } else {
      res = await favorItemDel(params)
      del = '取消'
    }

    if (res.success) {
      message.success(del + '收藏成功')
      this.getData()
    } else {
      message.success(del + '收藏失败')
    }
  }
  async getOrderStuList() {
    let stu = null
    let user = umbrella.getLocalStorage('user')
    if (user && user.studentJson) {
      stu = user.studentJson[0]
    }
    const params = {
      studentId: stu.studentId,
      orderTypeId: this.state.trainId,
      orderType: 2,
    }
    const res = await orderStuList(params)

    if (res.success) {
      this.setState({
        stuOrderList: res.data,
      })
    }
  }

  async startStudy() {
    let res = null
    const params = {
      orderType: 2,
      orderTitle: this.state.train.title,
      orderTypeId: this.state.trainId,
    }
    res = await createOrderAuto(params)
    if (res.success) {
      this.getData()
    } else {
      message.error(res.msg)
    }
  }

  public render() {
    const { train, loginStu } = this.state
    const startDate = new Date(train.enrollStartDate)
    const endDate = new Date(train.enrollEndDate)
    const now = new Date()
    const isLate = moment(moment(train.enrollEndDate).format('YYYY-MM-DD')).isBefore(
      moment(moment().format('YYYY-MM-DD'))
    )
    const isOverDate = now.getTime() < startDate.getTime() || isLate
    return (
      <div>
        <div className="app_layout_main">
          <Breadcrumb />
        </div>
        <div className="app_layout_main">
          <div className="course_detail_body">
            <div className="flex">
              <div className="course-box">
                <img
                  className="img"
                  src={train.coverDirectURL ? IMAGE_SERVER + train.coverDirectURL : noImage}
                  alt=""
                />
              </div>
              <div className="course-box ml-m">
                <div className="title">
                  {train.title}
                  <Tag color="orange" className="ml-s">
                    项目
                  </Tag>
                  {train.categoryName && <Tag color="blue">{train.categoryName} </Tag>}
                </div>
                <div className="detail mt-s">
                  <span className="text-gray">
                    <span className="text-yellow mr-s">
                      {train.itemCourJson && train.itemCourJson.length}
                    </span>
                    课程
                  </span>
                  <span className="mt-s ml-m text-gray">
                    <span className="text-yellow mr-s">{train.popularValue}</span>人气值
                  </span>
                  <div className="mt-s text-gray">
                    报名时间：
                    <Tag color={isOverDate ? 'gold' : 'green'}>{train.enrollStartDate}</Tag>/ {'  '}
                    <Tag color={isOverDate ? 'gold' : 'green'}>{train.enrollEndDate} </Tag>
                  </div>
                  <div className="flex mt-s">
                    <div className="text-gray">
                      <span style={{ textDecoration: 'line-through' }}>
                        ￥{train.standardPrice}
                      </span>
                      <span className="price text-2x text-yellow">￥{train.prefePrice}</span>
                    </div>
                  </div>
                  <div className="flex-vcenter mt-m ">
                    {!hasLogin() && <Login buttonTitle="去报名" />}
                    {hasLogin() &&
                      train.itemId &&
                      !isOverDate &&
                      train.isEnroll != 1 &&
                      loginStu &&
                      this.state.stuOrderList.length < 1 && (
                        /*  train.stuLevel > loginStu.stuLevel &&*/ <Link
                          className="mr-m"
                          to={'/app/train/orderConfirm?type=train&id=' + this.state.trainId}
                          color="gray"
                        >
                          <Button type="primary" block>
                            报名学习
                          </Button>
                        </Link>
                      )}
                    {/*
                    {hasLogin() &&
                      train.itemId &&
                      !isOverDate &&
                      train.isEnroll != 1 &&
                      loginStu &&
                      train.stuLevel <= loginStu.stuLevel && (
                        <Button
                          type="primary"
                          className="mr-m"
                          block
                          onClick={() => {
                            this.startStudy()
                          }}
                        >
                          开始学习
                        </Button>
                      )}
                      */}
                    {hasLogin() && train.itemId && isOverDate && (
                      <Button type="primary" disabled block>
                        此项目已过期
                      </Button>
                    )}
                    {hasLogin() && train.itemId && (
                      <Button
                        type="primary"
                        ghost
                        icon="star"
                        onClick={() => {
                          this.favorItem(train.itemId, train.isFavorite == 0)
                        }}
                      >
                        {train.isFavorite === '1' ? '取消收藏' : '收藏'}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <span
              className="course-desc"
              dangerouslySetInnerHTML={{ __html: train.abstract }}
            ></span>
          </div>
          <Tabs
            tabBarStyle={{
              borderBottomColor: 'transparent',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <TabPane tab="课程目录" key="cour_directory">
              <div className="flex-wrapper">
                {train.itemCourJson &&
                  train.itemCourJson.map((course: any, index: any) => {
                    let status: any = 'process'
                    switch (course.learnStatus) {
                      case 1:
                        status = 'process'
                        break
                      case 2:
                        status = 'wait'
                        break
                      case 3:
                        status = 'finish'
                        break
                      default:
                        status = 'finish'
                        break
                    }
                    return (
                      <CourseCell
                        course={course}
                        type={2}
                        typeId={this.state.trainId}
                        isEnroll={train.isEnroll == 1}
                        modal
                      />
                    )
                  })}
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    )
  }
}

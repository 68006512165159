/**
 * Created by hao.cheng on 2017/4/16.
 */
import { get, post, downBlob } from './tools'
import * as config from './config'
import umbrella from 'umbrella-storage'
import { message } from 'antd'
import { parseTime } from './../utils/index'
import saveAs from './../libs/FileSaver'

export const authLogin = async (params: any) => {
  return await post({
    url: config.LOGIN,
    data: params,
  })
}

export const autoLoginBySessionId = async (sessionId: any) => {
  return await get({
    url: config.LOGIN_BY_SESSIONID + sessionId,
  })
}

export const validCodeBase64 = async () => {
  const timestamp = new Date().getTime()
  const res = await get({
    url: config.VALID_CODE + `?t=${timestamp}`,
  })
  const data = res.data[0]
  if (res.headers.rsessionid) {
    umbrella.setLocalStorage('rsessionid', res.headers.rsessionid)
  }

  return data ? data.codeFClouPic : ''
}

export const register = async (params: any) => {
  return await post({
    url: config.REGISTER,
    data: params,
  })
}

export const apiSendSmsCode = async (params: any) => {
  return await post({
    url: config.SMS_CODE,
    data: params,
  })
}

/**
 *
 * @param {String} url
 * @param {String} title
 * @param {Object} params
 */
export const download = async (url: any, title: any, params: any) => {
  message.loading({ content: '下载中...', url })
  const res = await downBlob({
    url,
    data: params,
    config: {
      responseType: 'blob',
    },
  })
  const blob = res.data
  if (blob.size !== 0) {
    const date = parseTime(new Date(), '{y}{m}{d}{h}{m}{s}')
    const fileName = `${title}${date}.xlsx`
    saveAs(blob, fileName)
    message.success({ content: '下载完毕!', url, duration: 2 })
  }
  return res
}

export const gkfw_messagePush = async (params: any) => {
  return await post({
    url: config.GJFU_MSG_PUSH,
    data: {
      data: params,
    },
  })
}

export const homeRecommends = async (params: any) => {
  return await post({
    url: config.HOME_RECOMMEND,
    data: params,
  })
}

export const planList = async (params: any) =>
  await post({
    url: config.PLAN_LIST,
    data: params,
  })

export const trainList = async (params: any) =>
  await post({
    url: config.TRAIN_LIST,
    data: params,
  })

export const courseList = async (params: any) =>
  await post({
    url: config.COURSE_LIST,
    data: params,
  })

export const myLearnList = async (params: any) =>
  await post({
    url: config.MY_LEARN_LIST,
    data: params,
  })

export const courseOne = async (params: any) =>
  await post({
    url: config.COURSE_ONE,
    data: params,
  })

export const planOne = async (params: any) =>
  await post({
    url: config.PLAN_ONE,
    data: params,
  })
export const trainOne = async (params: any) =>
  await post({
    url: config.TRAIN_ONE,
    data: params,
  })

export const myOrderList = async (params: any) =>
  await post({
    url: config.ORDER_LIST,
    data: params,
  })

export const userDetail = async (params: any) =>
  await post({
    url: config.USER_DETAIL,
    data: params,
  })

export const orderStuAddMod = async (params: any) =>
  await post({
    url: config.ORDER_STU_ADD_MOD,
    data: params,
  })

export const createOrder = async (params: any) =>
  await post({
    url: config.CREATE_ORDER,
    data: params,
  })

export const trainType = async (params: any) =>
  await post({
    url: config.TRAIN_TYPE,
    data: params,
  })

export const orderCancel = async (params: any) =>
  await post({
    url: config.ORDER_CANCEL,
    data: params,
  })

export const orderOne = async (orderId: any) =>
  await post({
    url: config.ORDER_ONE,
    data: {
      orderId: orderId,
    },
  })
export const orderStuAdd = async (params: any) => {
  return await post({
    url: config.ORDER_STU_ADD,
    data: params,
  })
}

export const orderStuDel = async (orderStuId: any) => {
  console.log(orderStuId)
  return await post({
    url: config.ORDER_STU_DEL,
    data: {
      orderStuId: orderStuId,
    },
  })
}

export const orderStudentImp = async (params: any) => {
  return await post({
    url: config.ORDER_STU_IMP,
    data: {
      fileJson: JSON.stringify(params),
    },
  })
}

export const favorItem = async (params: any) => {
  return await post({
    url: config.FAVOR_ITEM,
    data: params,
  })
}

export const favorItemDel = async (params: any) => {
  return await post({
    url: config.FAVOR_ITEM_DEL,
    data: params,
  })
}

export const favorList = async (params: any) => {
  return await post({
    url: config.FAVOR_LIST,
    data: params,
  })
}

export const pay = async (params: any) => {
  return await post({
    url: config.PAY,
    data: params,
  })
}

export const qrCodePay = async (orderId: any) => {
  return await post({
    url: config.QR_PAY,
    data: {
      orderId: orderId,
    },
  })
}

export const homeAdvList = async (params: any) =>
  await post({
    url: config.HOME_ADV_LIST,
    data: params,
  })

export const homeRecommendList = async (params: any) =>
  await post({
    url: config.HOME_REC_LIST,
    data: params,
  })

export const categoryList = async (params: any) =>
  await post({
    url: config.CATEGORY_LIST,
    data: params,
  })

export const categoryMore = async (params: any) =>
  await post({
    url: config.CATEGORY_MORE,
    data: params,
  })

export const homeExpertList = async (params: any) =>
  await post({
    url: config.EXPERT_LIST,
    data: params,
  })

export const channelList = async (params: any) =>
  await post({
    url: config.CHANNEL_LIST,
    data: params,
  })

export const compList = async (params: any) => {
  return await post({
    url: config.COMP_LIST,
    data: params,
  })
}

export const saveCompleteStu = async (params: any) => {
  return await post({
    url: config.COMPLETE_STU,
    data: params,
  })
}

export const getGlobalRegion = async (params: any) => {
  return await post({
    url: config.GLOBAL_REGION,
    data: params,
  })
}

export const orderPayOffline = async (params: any) => {
  return await post({
    url: config.ORDER_PAY_OFFLINE,
    data: params,
  })
}

export const modPwd = async (params: any) => {
  return await post({
    url: config.MOD_PWD,
    data: params,
  })
}

export const createOrderAuto = async (params: any) => {
  return await post({
    url: config.CREATE_ORDER_AUTO,
    data: params,
  })
}

export const channelOne = async (params: any) =>
  await post({
    url: config.CHANNEL_ONE,
    data: params,
  })

export const expertOne = async (params: any) =>
  await post({
    url: config.EXPERT_ONE,
    data: params,
  })

export const chapterTextProgressUpdate = async (params: any) =>
  await post({
    url: config.CHAPTER_TEXT_PROGRESS_UPDATE,
    data: params,
  })

export const chapterTextProgressFinished = async (params: any) =>
  await post({
    url: config.CHAPTER_TEXT_PROGRESS_FINISHED,
    data: params,
  })

export const myInvoiceList = async (params: any) =>
  await post({
    url: config.MY_INVOICE,
    data: params,
  })
export const invoiceSave = async (params: any) =>
  await post({
    url: config.INVOICE_ADD,
    data: params,
  })
export const invoiceOne = async (params: any) =>
  await post({
    url: config.INVOICE_ONE,
    data: {
      invoiceId: params,
    },
  })
export const invoiceCompAdd = async (params: any) =>
  await post({
    url: config.INVOICE_COMP_ADD,
    data: params,
  })

export const invoiceComp = async (params: any) =>
  await post({
    url: config.INVOICE_COMP,
    data: params,
  })

export const orderStuList = async (params: any) => {
  return await post({
    url: config.ORDER_STU_LIST,
    data: params,
  })
}
export const orderStuOne = async (orderStuId: any) => {
  return await post({
    url: config.ORDER_STU_ONE,
    data: {
      orderStuId: orderStuId,
    },
  })
}
export const certsList = async (params: any) => {
  return await post({
    url: config.CERT_LIST,
    data: params,
  })
}

export const courseSettingList = async (params: any) => {
  return await post({
    url: config.COURSE_SETTING,
    data: params,
  })
}

//学员信息列表
export const studentList = async (params: any) => {
  return await post({
    url: config.STU_LIST,
    data: params,
  })
}

export const ccieTypeList = async (params: any) => {
  return await post({
    url: config.CCIETYPE_LIST,
    data: params,
  })
}

import * as React from 'react'
import { Input, Tag, Divider, Pagination, Form } from 'antd'
import { Link } from 'react-router-dom'
import Breadcrumb from '../Breadcrumb'
import { homeRecommendList } from '../../service/index'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connectAlita } from 'redux-alita'
import { FormComponentProps } from 'antd/lib/form'
import { IMAGE_SERVER } from '../../service/config'
import noImage from '../../style/imgs/no_image.png'

export interface IHotRecommendListProps extends FormComponentProps, RouteComponentProps<any> {}

interface IHotRecommendListStates {
  type: String
  querySearchText: any
  searchText: any
  recommendList: any[]
  page: number
  pageSize: number
  total: number
}

class HotRecommendList extends React.Component<IHotRecommendListProps, IHotRecommendListStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      type: props.query.type,
      querySearchText: '',
      searchText: '',
      recommendList: [],
      page: 1,
      pageSize: 10,
      total: 0,
    }
  }

  componentDidMount() {
    this.initData()
  }

  initData() {
    this.getRecommendList()
  }

  async getRecommendList() {
    const params = { page: this.state.page, rows: this.state.pageSize }
    const res = await homeRecommendList(params)
    if (res.success) {
      this.setState({
        recommendList: res.data,
        total: res.total,
      })
    }
  }

  public render() {
    return (
      <div className="app_layout_main">
        <Breadcrumb />
        <div className="flex-wrapper">
          {this.state.recommendList &&
            this.state.recommendList.map((item: any) => (
              <a href={item.recommendURL} target="_blank">
                <div className="course-box mt-m mr-m">
                  <img
                    className="img"
                    src={item.recommendDirectURL ? IMAGE_SERVER + item.recommendDirectURL : noImage}
                    alt=""
                  />
                  <div className="title">{item.recommendName}</div>
                </div>
              </a>
            ))}
        </div>
        <div className="flex-h-end mt-m">
          <Pagination
            current={this.state.page}
            pageSize={this.state.pageSize}
            total={this.state.total}
            onChange={(page: any) => {
              setTimeout(() => {
                this.setState({
                  page: page,
                })

                this.getRecommendList()
              }, 0)
            }}
          />
        </div>
      </div>
    )
  }
}

// 重新设置连接之后组件的关联类型
const trainListForm = Form.create<IHotRecommendListProps>()(HotRecommendList)
const HotRecommendListConnect: React.ComponentClass<
  IHotRecommendListProps,
  IHotRecommendListStates
> = connectAlita([{}])(trainListForm)
export default withRouter(HotRecommendListConnect)

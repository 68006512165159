import React, { Component } from 'react'
import { orderOne, qrCodePay, orderPayOffline } from './../../service/index'
import {
  Descriptions,
  Divider,
  Button,
  Card,
  Form,
  InputNumber,
  Input,
  DatePicker,
  message,
} from 'antd'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import { FormComponentProps } from 'antd/lib/form'
import Uploader from './../widget/Uploader'
import moment from 'moment'

const win: any = window
const QRCode: any = win.QRCode || {}

export interface IOrderPayResultProps extends FormComponentProps, RouteComponentProps<any> {}
interface IOrderPayResultState {
  orderId: any
  qrCode: any
  qrCodeEl: any
  payType: any
  isSetFile: any
  order: any
}

class OrderPayResult extends Component<IOrderPayResultProps, IOrderPayResultState> {
  constructor(props: any) {
    super(props)
    this.state = {
      orderId: props.query.orderId,
      payType: props.query.type,
      qrCode: null,
      qrCodeEl: <div id="qrcode"></div>,
      isSetFile: 0,
      order: {},
    }
  }
  componentDidMount() {
    this.getOrder()
    if (this.state.payType == 1) {
      this.getQRCode()
    }
  }
  getOrder = async () => {
    if (this.state.orderId) {
      const res = await orderOne(this.state.orderId)
      if (res.msgStatsNum === 10) {
        message.warn('您还未登录！')
        return
      }
      if (res.success) {
        const order = res.data[0]
        if (order) {
          this.setState({ order: order })
          this.props.form.setFieldsValue({ payAmount: order.totalAmount })
        }
      }
    }
  }

  getQRCode = async () => {
    if (this.state.orderId) {
      const res = await qrCodePay(this.state.orderId)
      if (res.msgStatsNum === 10) {
        message.warn('您还未登录！')
        return
      }
      if (res.success) {
        const result = res.data[0]
        const { txnTokenPayData } = result
        const payData = JSON.parse(txnTokenPayData)
        if (payData) {
          if (!this.state.qrCode) {
            console.log(QRCode)
            const qrCodeConfig = {
              text: payData.tokenCodeURL,
              width: 128,
              height: 128,
              colorDark: '#000000',
              colorLight: '#ffffff',
              correctLevel: QRCode.CorrectLevel.H,
            }
            const qrCode = new QRCode(document.getElementById('qrcode'), qrCodeConfig)
            this.setState({
              qrCode,
            })
          } else {
            this.state.qrCode.clear() // clear the code.
            this.state.qrCode.makeCode(payData.tokenCodeURL) // make another code.
          }
        }
      }
    }
  }

  handleSubmit = async () => {
    this.props.form!.validateFields(async (err, values) => {
      if (!err) {
        const dateFormat = 'YYYY-MM-DD'
        const payDate = moment(values.date).format(dateFormat)
        const params = {
          ...values,
          orderId: this.state.orderId,
          payDate: payDate,
          date: [],
          fileJson: JSON.stringify(values.fileList),
          fileList: [],
          isSetFile: this.state.isSetFile,
        }
        const res = await orderPayOffline(params)
        if (res.success) {
          message.success(res.msg)
          this.props.history.push('/app/user/orderDetail?orderId=' + this.state.orderId)
        } else {
          message.error(res.msg)
        }
      }
    })
  }
  render() {
    let typeStr = '手机扫码付款'
    if (this.state.payType == 2) {
      typeStr = '提交付款凭证'
    }
    const { getFieldDecorator } = this.props.form!
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    }
    const form = this.props.form!
    const { order } = this.state
    return (
      <div className="app_layout_main">
        <Descriptions title={order.orderTitle} column={1}>
          <Descriptions.Item label="商品订单">{order.orderNum}</Descriptions.Item>
          <Descriptions.Item label="订单名称">{order.orderTitle}</Descriptions.Item>
          <Descriptions.Item label="单价">{order.unitPrice || 0}</Descriptions.Item>
          <Descriptions.Item label="人数">{order.enrollCount || 0}</Descriptions.Item>
          <Descriptions.Item label="合计金额">{order.totalAmount || 0}</Descriptions.Item>
        </Descriptions>
        {this.state.payType == 1 && (
          <div>
            <Descriptions title={typeStr} column={1}>
              <Descriptions.Item label="支付方式">支付宝/微信</Descriptions.Item>
            </Descriptions>
            <div id="qrcode"></div>
            <div className="tips mt-s mb-m">
              说明： 1、请用手机中的微信、支付宝App扫描以上二维码，完成订单的支付；
              2、您也可以拍照保存以上二维码，在二维码有效期内完成订单的支付。
            </div>
            <Link className="mt-m" to={'/app/user/orderDetail?orderId=' + order.orderId}>
              <Button type="primary" onClick={() => {}}>
                我已保存二维码，稍后支付
              </Button>
            </Link>
            <Link className="mt-m ml-m" to={'/app/user/orderDetail?orderId=' + order.orderId}>
              <Button type="primary" onClick={() => {}}>
                我已扫码并完成支付
              </Button>
            </Link>
          </div>
        )}
        {this.state.payType == 2 && (
          <div>
            <Card>
              <Form
                {...formItemLayout}
                style={{ marginBottom: 16, width: '50%', marginLeft: 0 }}
                title="提交付款凭证"
              >
                <Form.Item label="付款金额">
                  {getFieldDecorator('payAmount', {
                    rules: [{ required: true, message: '请输入实际付款金额!' }],
                  })(<InputNumber min={0} />)}
                  <div className="tips">请填写您实际支付的金额（与付款凭证一致）</div>
                </Form.Item>
                <Form.Item label="付款银行">
                  {getFieldDecorator('payBankName', {
                    rules: [{ required: true, message: '请输入付款银行!' }],
                  })(<Input placeholder="付款银行" allowClear />)}
                </Form.Item>
                <Form.Item label="付款户名">
                  {getFieldDecorator('payAccountName', {
                    rules: [{ required: true, message: '请输入付款户名!' }],
                  })(<Input placeholder="付款户名" allowClear />)}
                </Form.Item>
                <Form.Item label="付款账号">
                  {getFieldDecorator('payBankAccount', {
                    rules: [{ required: true, message: '请输入付款账号!' }],
                  })(<Input placeholder="付款账号" allowClear />)}
                  <div className="tips">数字之间不可有空格</div>
                </Form.Item>
                <Form.Item label="付款日期">
                  {getFieldDecorator('date', {
                    rules: [{ required: true, message: '请输入付款日期!' }],
                  })(<DatePicker />)}
                </Form.Item>
                <Form.Item label="付款凭证">
                  {getFieldDecorator('fileList', {
                    valuePropName: 'fileList',
                    rules: [
                      {
                        required: true,
                        message: '请上传付款凭证!',
                        validator: (rule, value, callback) => {
                          if (this.state.isSetFile === 0) {
                            callback()
                          } else {
                            if (!form.getFieldValue('fileList')) {
                              callback('请上传付款凭证!')
                            } else {
                              callback()
                            }
                          }
                        },
                      },
                    ],
                    normalize(e: any) {
                      if (Array.isArray(e)) return e
                      return e && e.fileList
                    },
                  })(
                    <Uploader
                      handleUploadFileChange={(fileList: any) => {
                        form.setFieldsValue({
                          fileList: fileList,
                        })
                        this.setState({
                          isSetFile: fileList.length > 0 ? 1 : 0,
                        })
                      }}
                    />
                  )}
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                  <Button
                    type="primary"
                    onClick={() => {
                      this.handleSubmit()
                    }}
                  >
                    提交
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
        )}
      </div>
    )
  }
}

export default Form.create<IOrderPayResultProps>()(withRouter(OrderPayResult))

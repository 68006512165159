import * as React from 'react'
import { Input, Tag, Divider, Pagination, Form } from 'antd'
import { Link } from 'react-router-dom'
import Breadcrumb from '../Breadcrumb'
import { homeRecommends } from '../../service/index'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connectAlita } from 'redux-alita'
import { FormComponentProps } from 'antd/lib/form'
import { IMAGE_SERVER } from '../../service/config'
import noImage from '../../style/imgs/no_image.png'

export interface IHotCourseProps extends FormComponentProps, RouteComponentProps<any> {}

interface IHotCourseStates {
  type: String
  dataList: any[]
  page: number
  pageSize: number
  total: number
}

class HotCourse extends React.Component<IHotCourseProps, IHotCourseStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      type: props.query.type,
      dataList: [],
      page: 1,
      pageSize: 10,
      total: 0,
    }
  }

  componentDidMount() {
    this.initData()
  }

  initData() {
    this.getData()
  }

  async getData() {
    const params = { page: this.state.page, rows: this.state.pageSize }
    const res = await homeRecommends(params)
    if (res.success) {
      const { courseJson, itemJson, planJson } = res.data[0]
      const { type } = this.state
      if (type === 'plan') {
        this.setState({
          dataList: planJson,
          total: res.total,
        })
      } else if (type === 'train') {
        this.setState({
          dataList: itemJson,
          total: res.total,
        })
      } else if (type === 'course') {
        this.setState({
          dataList: courseJson,
          total: res.total,
        })
      }
    }
  }

  public render() {
    const { type } = this.state
    return (
      <div className="app_layout_main">
        <Breadcrumb />
        <div className="flex-wrapper">
          {type === 'plan' &&
            this.state.dataList &&
            this.state.dataList.map((item: any) => (
              <Link to={`/app/train/list/plan?id=${item.planId}`} color="gray">
                <div className="course-box mt-m mr-m">
                  <img
                    className="img"
                    src={item.coverDirectURL ? IMAGE_SERVER + item.coverDirectURL : noImage}
                    alt=""
                  />
                  <div className="title">{item.title}</div>
                </div>
              </Link>
            ))}
          {type === 'train' &&
            this.state.dataList &&
            this.state.dataList.map((item: any) => (
              <Link to={`/app/train/list/train?id=${item.itemId}`} color="gray">
                <div className="course-box mt-m mr-m">
                  <img
                    className="img"
                    src={item.coverDirectURL ? IMAGE_SERVER + item.coverDirectURL : noImage}
                    alt=""
                  />
                  <div className="title">{item.title}</div>
                </div>
              </Link>
            ))}
          {type === 'course' &&
            this.state.dataList &&
            this.state.dataList.map((item: any) => (
              <Link to={`/app/train/list/course?id=${item.courseId}`} color="gray">
                <div className="course-box mt-m mr-m">
                  <img
                    className="img"
                    src={item.coverDirectURL ? IMAGE_SERVER + item.coverDirectURL : noImage}
                    alt=""
                  />
                  <div className="title">{item.title}</div>
                </div>
              </Link>
            ))}
        </div>
        <div className="flex-h-end mt-m">
          <Pagination
            current={this.state.page}
            total={this.state.total}
            pageSize={this.state.pageSize}
            onChange={(page: any) => {
              setTimeout(() => {
                this.setState({
                  page: page,
                })

                this.getData()
              }, 0)
            }}
          />
        </div>
      </div>
    )
  }
}

// 重新设置连接之后组件的关联类型
const trainListForm = Form.create<IHotCourseProps>()(HotCourse)
const HotCourseConnect: React.ComponentClass<IHotCourseProps, IHotCourseStates> = connectAlita([
  {},
])(trainListForm)
export default withRouter(HotCourseConnect)
